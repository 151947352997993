<!--
 * @Description: 选择公司
 * @Author: 琢磨先生
 * @Date: 2023-03-22 15:59:16
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-22 17:36:48
-->
<template>
  <el-select
    v-model="company_id"
    placeholder="搜索选择"
    clearable
    filterable
    remote
    :loading="loading"
    :remote-method="remoteSearch"
    style="width:300px;"
    @change="change"
  >
    <el-option :label="item.label" :value="item.value" v-for="item in companies" :key="item.value"></el-option>
  </el-select>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      company_id: "",
      companies: [],
      q: "",
    };
  },
  emits: ["change"],
  props: ["item"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          if (!this.companies.find((o) => o.value == this.item.id)) {
            (this.company_id = this.item.id);
              this.companies.push({
                value: this.item.id,
                label: this.item.name,
              });
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 远程搜索
     */
    remoteSearch(val) {
      this.q = val;
      this.loadData();
    },

    /**
     * 选择更改
     */
    change(item) {
      this.$emit("change", item);
    },

    loadData() {
      this.$http.get("admin/v1/company/get_select?q=" + this.q).then((res) => {
        if (res.code == 0) {
          this.companies = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>