<!--
 * @Description: 人员管理
 * @Author: 琢磨先生
 * @Date: 2023-02-28 22:18:22
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-08 09:32:06
-->
<template>
  <el-card class="box query">
    <el-form
      :model="query"
      ref="query"
      :inline="true"
      @submit.prevent="onSearch"
    >
      <el-form-item label="公司" v-if="!isFromCompany">
        <el-input
          v-model="query.company_name"
          placeholder="公司名称"
          clearable
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="关键字">
        <el-input
          v-model="query.q"
          placeholder="姓名、手机号"
          clearable
          style="width: 200px"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="query.isDisabled"
          placeholder
          style="width: 100px"
          clearable
        >
          <el-option label="正常" :value="false"></el-option>
          <el-option label="禁用" :value="true"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开放" v-if="!isFromCompany">
        <el-select
          v-model="query.isOpen"
          placeholder
          style="width: 100px"
          clearable
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="未开放" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="测试">
        <el-select
          v-model="query.isTest"
          placeholder
          style="width: 100px"
          clearable
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="已删">
        <el-select
          v-model="query.isDel"
          placeholder
          style="width: 100px"
          clearable
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          round
          @click="onSearch"
          icon="search"
          native-type="submit"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div class="actions">
      <el-button
        type="primary"
        size="small"
        icon="plus"
        v-if="is_can_edit"
        round
        @click="onEdit()"
        >新增</el-button
      >

      <el-popconfirm
        width="220"
        v-if="isFromCompany && is_can_open"
        title="您确定要开放所有人员账号？"
        @confirm="onOpenAll"
      >
        <template #reference>
          <el-button type="success" size="small" icon="Check" round
            >开放所有</el-button
          >
        </template>
      </el-popconfirm>
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table
      :data="tableData.data"
      border
      stripe
      :cell-class-name="delCellClassHandled"
    >
      <el-table-column
        label="姓名"
        prop="name"
        min-width="150"
        fixed="left"
      ></el-table-column>
      <el-table-column label="手机" prop="mobile" width="150"></el-table-column>
      <el-table-column
        label="公司"
        prop="companyName"
        min-width="250"
        v-if="!isFromCompany"
      ></el-table-column>
      <el-table-column label="主账号" width="100">
        <template #default="scope">
          {{ scope.row.isAdmin ? "是" : "" }}
          <el-button
            type="primary"
            link
            size="small"
            v-if="
              !scope.row.isDeleted &&
              !scope.row.isAdmin &&
              scope.row.companyId != 1
            "
            @click="onSetAdmin(scope.row)"
            >设为主账号</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="性别" prop="sex" width="100"></el-table-column>
      <el-table-column label="状态" prop="enabled" width="120">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.disabled">禁用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="开放" width="100">
        <template #default="scope">
          <el-link type="success" :underline="false" v-if="scope.row.isOpen"
            >是</el-link
          >
          <el-link type="danger" :underline="false" v-else>未开放</el-link>
        </template>
      </el-table-column>
      <el-table-column label="测试" width="100">
        <template #default="scope">
          <el-link type="danger" :underline="false" v-if="scope.row.isTest"
            >测试</el-link
          >
          <el-link type="info" :underline="false" v-else>--</el-link>
        </template>
      </el-table-column>
      <el-table-column label="已删除(时间)" width="200">
        <template #default="scope">
          {{ scope.row.isDeleted ? `是(${scope.row.deletedTime})` : "--" }}
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="creationTime"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            icon="edit"
            v-if="is_can_edit"
            >修改</el-button
          >
          <el-popconfirm
            width="220"
            v-if="is_can_reset"
            title="您确定要重置密码？"
            @confirm="onResetPwd(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                link
                icon="refresh"
                :loading="scope.row.deleting"
                >重置密码</el-button
              >
            </template>
          </el-popconfirm>

          <el-popconfirm
            width="220"
            v-if="!scope.row.isDeleted && is_can_logout"
            title="您确定要下退出登录状态？"
            @confirm="onLogout(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                link
                type="warning"
                icon="SwitchButton"
                :loading="scope.row.deleting"
                >退出登录</el-button
              >
            </template>
          </el-popconfirm>

          <el-popconfirm
            width="220"
            v-if="!scope.row.isDeleted && is_can_del"
            title="您确定要删除此用户？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
                link
                icon="delete"
                :loading="scope.row.deleting"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
          <el-popconfirm
            width="220"
            v-if="scope.row.isDeleted"
            title="您确定要恢复？"
            @confirm="onRenew(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                type="info"
                link
                icon="RefreshLeft"
                :loading="scope.row.deleting"
                >恢复</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <edit-consumer
    :item="current_item"
    :company="company"
    @success="onSuccess"
  ></edit-consumer>
</template>

<script>
import EditConsumer from "./edit_consumer.vue";
export default {
  components: {
    EditConsumer,
  },
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        sort: {
          order: "",
          prop: "",
        },
        q: "",
        company_name: "",
      },
      avatarUrl: require("@/assets/avatar.png"),
      tableData: {
        counts: 0,
      },
      // isNotCompany: false,
      current_item: null,
      is_can_edit: false,
      is_can_del: false,
      is_can_reset: false,
      is_can_logout: false,
      is_can_open: false,
    };
  },
  // props: ["isFromCompany", "companyName"],
  props: {
    isFromCompany: Boolean,
    // companyId: Number,
    // companyName: String,
    company: Object,
  },
  watch: {
    isFromCompany: {
      handler() {
        // this.isNotCompany = !this.isFromCompany;
      },
      immediate: true,
    },
    // companyName: {
    //   handler() {
    //     this.query.company_name = "";
    //     if (this.companyName) {
    //       this.query.company_name = this.companyName;
    //     }
    //   },
    //   immediate: true,
    // },
    company: {
      handler() {
        this.query.company_name = "";
        if (this.company) {
          this.query.company_name = this.company.companyName;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.is_can_edit = this.$power("admin/v1/consumer/edit");
    this.is_can_del = this.$power("admin/v1/consumer/del");
    this.is_can_reset = this.$power("admin/v1/consumer/resetpwd");
    this.is_can_logout = this.$power("admin/v1/consumer/logout");
    this.is_can_open = this.$power("admin/v1/consumer/open");
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      //   this.current = null;
      this.loading = true;
      this.$http
        .post("/admin/v1/consumer", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.sort = {
          order: e.order,
          prop: e.prop,
        };
        this.onSearch();
      }
    },

    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 修改
     */
    onEdit(item) {
      this.current_item = Object.assign({}, item);
    },

    /**
     *
     */
    onSuccess() {
      this.current_item = null;
      this.loadData();
    },

    /**
     * 删除
     */
    onDelete(item) {
      item.deleting = true;
      this.$http.get(`admin/v1/consumer/del?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          if (this.query.pageIndex > 1) {
            if (this.tableData.data.length <= 1) {
              this.query.pageIndex--;
            }
          }
          this.loadData();
        }
      });
    },

    /**
     * 重置密码
     */
    onResetPwd(item) {
      this.$http.get(`admin/v1/consumer/resetpwd?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          // this.loadData();
        }
      });
    },

    /**
     * 设置主账号
     * @param {*} item
     */
    onSetAdmin(item) {
      this.$confirm(`您确定要设置【${item.name}】为主账号？`, "温馨提示").then(
        () => {
          this.$http
            .get(`admin/v1/consumer/set_admin?id=${item.id}`)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.loadData();
              }
            });
        }
      );
    },

    /**
     * 退出登录状态
     * @param {*} item
     */
    onLogout(item) {
      this.$http.get(`admin/v1/consumer/logout?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
        }
      });
    },

    /**
     *
     * @param {*} e
     */
    delCellClassHandled(e) {
      if (e.row.isDeleted) {
        return "table-cell-del";
      }
    },

    /**
     * 恢复
     * @param {*} item
     */
    onRenew(item) {
      // item.renew = true;
      this.$http.get(`admin/v1/consumer/renew?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          // if (this.query.pageIndex > 1) {
          //   if (this.tableData.data.length <= 1) {
          //     this.query.pageIndex--;
          //   }
          // }
          this.loadData();
        }
      });
    },

    /**
     * 开放所有人员
     */
    onOpenAll() {
      this.$http
        .get(`admin/v1/consumer/open?companyId=${this.company.id}`)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.loadData();
          }
        });
    },
  },
};
</script>

<style scoped></style>
