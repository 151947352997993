<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-03-22 15:18:26
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-12-29 16:23:00
-->
<template>
  <el-dialog
    :title="title"
    v-model="visibleDialog"
    width="650px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="140px"
      :inline="false"
    >
      <el-form-item label="公司">
        <span v-if="company">{{ company.companyName }}</span>
        <choose-company
          v-else
          :item="selection_company"
          @change="chooseChange"
        ></choose-company>
      </el-form-item>

      <el-form-item label="姓名" prop="name">
        <el-col :span="10">
          <el-input v-model="form.name" placeholder></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-col :span="10">
          <el-input v-model="form.mobile" placeholder></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="form.sex">
          <el-radio label="男">男</el-radio>
          <el-radio label="女">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="form.disabled">
          <el-radio :label="true">禁用</el-radio>
          <el-radio :label="false">正常</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="测试？">
        <el-checkbox :label="true" v-model="form.isTest">是</el-checkbox>
        <div class="help-block">测试人员的报备将无法被正确处理</div>
      </el-form-item>

      <!-- <el-form-item label="登录密码">
        <el-col :span="10">
          <el-input v-model="form.pwd" placeholder="登陆密码"></el-input>
        </el-col>
        <div class="help-block">新增用户未填写登录密码则系统自动默认指定</div>
      </el-form-item> -->
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import ChooseCompany from "../component/choose_company.vue";

export default {
  components: {
    ChooseCompany,
  },
  data() {
    return {
      visibleDialog: false,
      saving: false,
      form: {
        disabled: false,
        sex: "男",
      },
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        account: [{ required: true, message: "请输入", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      title: "",
      selection_company: null,
    };
  },
  props: {
    item: Object,
    company: Object,
  },
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.visibleDialog = true;
          this.form = Object.assign(
            {
              disabled: false,
              sex: "男",
            },
            this.item
          );
          this.title = this.form.id ? "修改" : "新增";
          if (this.form.id) {
            this.selection_company = {
              id: this.form.companyId,
              name: this.form.companyName,
            };
          } else if (this.company) {
            this.form.companyId = this.company.id;
          }
        }
      },
      immediate: true,
    },
  },
  emits: ["success"],
  created() {},
  methods: {
    /**
     * 公司选择更改
     */
    chooseChange(id) {
      this.form.companyId = id;
      console.log("公司选择更改", this.form);
    },
    /**
     * 提交
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("admin/v1/consumer/edit", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.$emit("success");
                this.visibleDialog = false;
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
